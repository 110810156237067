import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { CheckCircle, XCircle, ChevronRight } from 'lucide-react';

const WorkflowExecutions = () => {
  // This would typically come from an API
  const sampleExecutions = [
    {
      id: 1,
      timestamp: '2024-11-02 14:30',
      status: 'success',
      steps: [
        { description: 'Navigate to page', status: 'success', screenshot: 'https://placehold.co/150x100/e2e8f0/64748b?text=Step+1' },
        { description: 'Fill login form', status: 'success', screenshot: 'https://placehold.co/150x100/e2e8f0/64748b?text=Step+2' }
      ]
    },
    {
      id: 2,
      timestamp: '2024-11-02 14:15',
      status: 'success',
      steps: [
        { description: 'Navigate to page', status: 'success', screenshot: 'https://placehold.co/150x100/e2e8f0/64748b?text=Step+1' },
        { description: 'Element not found', status: 'success', screenshot: 'https://placehold.co/150x100/e2e8f0/64748b?text=Step+2' },
        { description: 'Element not found', status: 'success', screenshot: 'https://placehold.co/150x100/e2e8f0/64748b?text=Step+3' }
        ]
    }
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle>Workflow Executions</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {sampleExecutions.map((execution) => (
            <div key={execution.id} className="border rounded-lg p-4 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    {execution.status === 'success' ? (
                      <CheckCircle className="text-green-500" size={16} />
                    ) : (
                      <XCircle className="text-red-500" size={16} />
                    )}
                    <span className="font-medium">Execution #{execution.id}</span>
                  </div>
                  <div className="text-sm text-gray-500">{execution.timestamp}</div>
                </div>
                <ChevronRight size={16} className="text-gray-400" />
              </div>
              
              <div className="mt-4 flex gap-2">
                {execution.steps.map((step, index) => (
                  <img
                    key={index}
                    src={step.screenshot}
                    alt={step.description}
                    className="w-20 h-16 object-cover rounded"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default WorkflowExecutions; 