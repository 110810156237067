const requiredEnvVars = [
  'VITE_API_URL',
  'VITE_PUSHER_KEY',
  'VITE_PUSHER_CLUSTER'
]

const config = {
  apiUrl: import.meta.env.VITE_API_URL,
  pusherKey: import.meta.env.VITE_PUSHER_KEY,
  pusherCluster: import.meta.env.VITE_PUSHER_CLUSTER
}

// Validate environment variables
requiredEnvVars.forEach(varName => {
  if (!import.meta.env[varName]) {
    console.error(`Missing required environment variable: ${varName}`)
  }
})

export default config 