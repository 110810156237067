import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthLayout from '../layouts/AuthLayout';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('initial'); // initial, verifying, success, error
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      setStatus('initial');
      return;
    }

    const verifyEmail = async () => {
      setStatus('verifying');
      try {
        await axios.post(
          `${import.meta.env.VITE_API_URL}/auth/verify-email/`,
          { token },
          { withCredentials: true }
        );
        setStatus('success');
        // Redirect to login after 3 seconds
        setTimeout(() => navigate('/login'), 3000);
      } catch (error) {
        console.error('Verification failed:', error);
        setStatus('error');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  const renderMessage = () => {
    switch (status) {
      case 'initial':
        return 'Please check your email for a verification link.';
      case 'verifying':
        return 'Verifying your email...';
      case 'success':
        return 'Email verified successfully! Redirecting to login...';
      case 'error':
        return 'Failed to verify email. Please try again or contact support.';
      default:
        return '';
    }
  };

  const getMessageColor = () => {
    switch (status) {
      case 'success':
        return 'text-green-600';
      case 'error':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <AuthLayout title={token ? "Email Verification" : "Verify your email"}>
      <div className="text-center">
        <p className={`mt-2 text-sm ${getMessageColor()}`}>
          {renderMessage()}
        </p>
      </div>
    </AuthLayout>
  );
};

export default VerifyEmail; 