import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Loader2, Play } from 'lucide-react';
import pusher from '../lib/pusherService';
import config from '../config'

const WebAutomation = () => {
  const [url, setUrl] = useState('');
  const [action, setAction] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [progressHistory, setProgressHistory] = useState([]);
  const [workflowId, setWorkflowId] = useState(null);
  const [isWorkflowComplete, setIsWorkflowComplete] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const [curlCommand, setCurlCommand] = useState(null);
  const apiSectionRef = useRef(null);

  useEffect(() => {
    if (!workflowId) return;

    console.log(`Subscribing to workflow-${workflowId}`);
    const channel = pusher.subscribe(`workflow-${workflowId}`);

    channel.bind('step-update', (data) => {
      console.log('Received step update:', data);

      if (data.status === 'completed') {
        setIsWorkflowComplete(true);
        if (data.message.startsWith('curl')) {
          setCurlCommand(data.message);
          return;
        }
      }

      setProgressHistory(prev => [...prev, {
        message: data.message,
        screenshot_path: data.screenshot_path,
        step_order: data.step_order,
        status: data.status
      }]);
    });

    channel.bind('subscription_succeeded', () => {
      console.log('Successfully subscribed to channel');
    });

    channel.bind('subscription_error', (error) => {
      console.error('Subscription error:', error);
    });

    return () => {
      console.log(`Unsubscribing from workflow-${workflowId}`);
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [workflowId]);

  useEffect(() => {
    if (progressHistory.length > 0) {
      const progressItems = document.querySelectorAll('.progress-history-item');
      if (progressItems.length > 0) {
        const lastProgressItem = progressItems[progressItems.length - 1];
        lastProgressItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [progressHistory]);

  useEffect(() => {
    if (curlCommand && apiSectionRef.current) {
      apiSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [curlCommand]);

  const handleExecute = async () => {
    setIsExecuting(true);
    setError(null);
    setResult(null);
    setProgressHistory([]);
    setIsWorkflowComplete(false);

    try {
      const response = await fetch(`${config.apiUrl}/workflows/execute/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: url,
          action: action,
          fields: {},
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to execute workflow');
      }

      setResult(data);
      setWorkflowId(data.workflow_id);
      console.log('Execution result:', data);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Create Automation</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Website URL</label>
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com"
              className="w-full p-1.5 border rounded-md text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">What would you like to do?</label>
            <textarea
              value={action}
              onChange={(e) => setAction(e.target.value)}
              rows="3"
              placeholder="Describe the actions to automate..."
              className="w-full p-1.5 border rounded-md text-sm"
            />
          </div>
          {!isExecuting && (
            <button
              className="flex items-center gap-2 px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed text-sm"
              onClick={handleExecute}
              disabled={!url || !action}
            >
              <Play size={16} />
              Execute Workflow
            </button>
          )}

          {isExecuting && (
            <div className="mt-4 p-4 pb-20 bg-blue-50 rounded-md">
              <h3 className="font-medium mb-2">Progress History:</h3>
              <div className="space-y-4">
                {progressHistory.map((step, index) => (
                  <div
                    key={index}
                    className={`progress-history-item text-sm p-4 bg-white rounded border ${step.status === 'failed' ? 'border-red-200' : 'border-blue-100'
                      }`}
                  >
                    <div className="mb-2">{step.message}</div>
                    {step.screenshot_path && (
                      <div className="mt-2">
                        <img
                          src={step.screenshot_path}
                          alt={`Step ${step.step_order} screenshot`}
                          className="max-w-full h-auto rounded-md border border-gray-200"
                          loading="lazy"
                        />
                      </div>
                    )}
                  </div>
                ))}

                {!isWorkflowComplete && (
                  <div className="text-sm p-4 bg-white rounded border border-blue-100">
                    <div className="flex items-center gap-3">
                      <Loader2 size={16} className="animate-spin" />
                      <span>Tabtrooper is working on your request...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {isWorkflowComplete && curlCommand && (
            <div ref={apiSectionRef} className="mt-8 p-6 bg-green-50 rounded-md">
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-gray-900">API Integration</h3>
                <p className="text-sm text-gray-600 mt-1">
                  You can rerun this workflow programmatically using the following curl command:
                </p>
              </div>
              <pre className="bg-black text-green-400 p-4 rounded-md overflow-x-auto whitespace-pre-wrap font-mono text-sm">
                {curlCommand}
              </pre>
            </div>
          )}

          {error && (
            <div className="text-red-500 mt-4">
              Error: {error}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default WebAutomation;