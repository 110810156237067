import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthLayout from '../layouts/AuthLayout';
import Spinner from '../components/Spinner';

const RequestPasswordReset = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [status, setStatus] = useState('initial'); // initial, success, error
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError('');
    setStatus('initial');
    try {
      await axios.post(
        `${import.meta.env.VITE_API_URL}/auth/reset-password/request/`,
        data,
        { withCredentials: true }
      );
      setStatus('success');
    } catch (error) {
      console.error('Password reset request failed:', error);
      if (error.response) {
        if (error.response.status === 400) {
          setError('Please enter a valid email address');
        } else {
          setError('An error occurred. Please try again later');
        }
      } else {
        setError('Unable to connect to the server');
      }
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  const renderMessage = () => {
    switch (status) {
      case 'success':
        return 'If an account exists with this email, you will receive password reset instructions.';
      default:
        return '';
    }
  };

  return (
    <AuthLayout title="Reset your password">
      <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{error}</h3>
              </div>
            </div>
          </div>
        )}

        {status === 'success' && (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  {renderMessage()}
                </h3>
              </div>
            </div>
          </div>
        )}

        <div>
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            {...register('email', { 
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address'
              }
            })}
            type="email"
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            placeholder="Email address"
            disabled={isLoading || status === 'success'}
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
          )}
        </div>

        {status !== 'success' && (
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner className="text-white" />
                  Sending instructions...
                </>
              ) : (
                'Send reset instructions'
              )}
            </button>
          </div>
        )}

        <div className="text-center">
          <Link
            to="/login"
            className={`font-medium text-blue-600 hover:text-blue-500 ${isLoading ? 'pointer-events-none opacity-50' : ''}`}
          >
            Back to login
          </Link>
        </div>
      </form>
    </AuthLayout>
  );
};

export default RequestPasswordReset; 