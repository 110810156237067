import Pusher from 'pusher-js';
import config from '../config';

// Enable Pusher logging
Pusher.logToConsole = true;

const pusher = new Pusher(config.pusherKey, {
  cluster: config.pusherCluster
});

// Log connection state changes
pusher.connection.bind('state_change', states => {
  console.log('Pusher state changed:', states);
});

export default pusher; 