import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import AuthLayout from '../layouts/AuthLayout';
import Spinner from '../components/Spinner';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [status, setStatus] = useState('initial'); // initial, success, error
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const token = searchParams.get('token');
  const password = watch('new_password');

  const onSubmit = async (data) => {
    if (!token) {
      setError('Invalid reset token');
      setStatus('error');
      return;
    }

    setIsLoading(true);
    setError('');
    try {
      await axios.post(
        `${import.meta.env.VITE_API_URL}/auth/reset-password/confirm/`,
        { token, new_password: data.new_password },
        { withCredentials: true }
      );
      setStatus('success');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      console.error('Password reset failed:', error);
      if (error.response) {
        switch (error.response.status) {
          case 400:
            if (error.response.data.new_password) {
              setError(error.response.data.new_password[0]);
            } else {
              setError('Please check your input and try again');
            }
            break;
          case 404:
            setError('Invalid or expired reset token');
            break;
          default:
            setError('An error occurred. Please try again later');
        }
      } else {
        setError('Unable to connect to the server');
      }
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return (
      <AuthLayout title="Reset Password">
        <div className="text-center">
          <div className="rounded-md bg-red-50 p-4 mb-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Invalid reset link. Please request a new password reset.
                </h3>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Link
              to="/request-password-reset"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Request password reset
            </Link>
          </div>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout title="Set new password">
      <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{error}</h3>
              </div>
            </div>
          </div>
        )}

        {status === 'success' && (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  Password reset successful! Redirecting to login...
                </h3>
              </div>
            </div>
          </div>
        )}

        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="new_password" className="sr-only">
              New Password
            </label>
            <input
              {...register('new_password', { 
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              })}
              type="password"
              className="appearance-none rounded-t-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="New password"
              disabled={isLoading || status === 'success'}
            />
            {errors.new_password && (
              <p className="text-red-500 text-sm mt-1">{errors.new_password.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="confirm_password" className="sr-only">
              Confirm Password
            </label>
            <input
              {...register('confirm_password', { 
                required: 'Please confirm your password',
                validate: value => value === password || 'Passwords do not match'
              })}
              type="password"
              className="appearance-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Confirm new password"
              disabled={isLoading || status === 'success'}
            />
            {errors.confirm_password && (
              <p className="text-red-500 text-sm mt-1">{errors.confirm_password.message}</p>
            )}
          </div>
        </div>

        {status !== 'success' && (
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner className="text-white" />
                  Resetting password...
                </>
              ) : (
                'Reset Password'
              )}
            </button>
          </div>
        )}
      </form>
    </AuthLayout>
  );
};

export default ResetPassword; 