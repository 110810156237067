import { useEffect } from 'react';

const DocumentHead = () => {
  useEffect(() => {
    // Update title
    document.title = APP_TITLE;
    
    // Update favicon
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = APP_FAVICON;
    } else {
      const newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      newFavicon.type = 'image/svg+xml';
      newFavicon.href = APP_FAVICON;
      document.head.appendChild(newFavicon);
    }
  }, []);

  return null;
};

export default DocumentHead; 