import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import Signup from './pages/Signup';
import VerifyEmail from './pages/VerifyEmail';
import RequestPasswordReset from './pages/RequestPasswordReset';
import ResetPassword from './pages/ResetPassword';

// Existing imports
import { useState } from 'react'
import WebAutomation from './components/WebAutomation'
import WorkflowExecutions from './components/WorkflowExecutions'
import DocumentHead from './components/DocumentHead'
import { Play, List, LogOut } from 'lucide-react'
import { useAuth } from './contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function App() {
  console.log('Environment:', {
    NODE_ENV: import.meta.env.MODE,
    apiUrl: import.meta.env.VITE_API_URL,
    pusherKey: import.meta.env.VITE_PUSHER_KEY,
    pusherCluster: import.meta.env.VITE_PUSHER_CLUSTER
  })

  const [currentView, setCurrentView] = useState('main')

  // Extract the workflow dashboard into a component
  const WorkflowDashboard = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
      await logout();
      navigate('/login');
    };

    return (
      <div className="max-w-2xl mx-auto p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <button
              className={`flex items-center gap-2 px-3 py-1.5 rounded-md text-sm ${
                currentView === 'main' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
              }`}
              onClick={() => setCurrentView('main')}
            >
              <Play size={14} /> Workflow
            </button>
            <button
              className={`flex items-center gap-2 px-3 py-1.5 rounded-md text-sm ${
                currentView === 'executions' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
              }`}
              onClick={() => setCurrentView('executions')}
            >
              <List size={14} /> Executions
            </button>
          </div>
          <button
            onClick={handleLogout}
            className="flex items-center gap-2 px-3 py-1.5 rounded-md text-sm text-red-600 hover:bg-red-50"
          >
            <LogOut size={14} /> Logout
          </button>
        </div>

        {currentView === 'main' ? <WebAutomation /> : <WorkflowExecutions />}
      </div>
    );
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <DocumentHead />
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* Protected routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <WorkflowDashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App